export const getPublicChatMessages = ({ chatId }) => `
  query {
    publicChatMessages(chatId: "${chatId}") {
      data {
        id
        adminId
        chatId
        createdAt
        subject
        text
        type
        waMessageId
        waMessageDateTime
        wppMediaId
        file {
          id
          name
          url
          type
        }
        subscriptionExtraData {
          chatWasClosed
        }
      }
    }
  }
`
