export const sendAPIClientMessageMutation = ({ chatId, text }) => `
  mutation {
    sendAPIClientMessage(
      chatId: "${chatId}"
      text: "${text}"
    ) {
      success
    }
  }
`
