import {
  LOCAL_STORAGE_CHAT_ID,
  LOCAL_STORAGE_CHAT_MESSAGES
} from '../config/dotEnv'

export function getChatMessagesInLocalStorage() {
  const chat = localStorage.getItem(LOCAL_STORAGE_CHAT_MESSAGES)

  if (!chat) {
    return null
  }

  return JSON.parse(chat)
}

export function removeChatMessagesInLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_CHAT_MESSAGES)
}

export function setChatMessagesInLocalStorage(value) {
  localStorage.setItem(
    LOCAL_STORAGE_CHAT_MESSAGES,
    JSON.stringify(value)
  )
}

export function setNewMessageInLocalStorageChatMessages(newMessage) {
  let currentChatMessages = getChatMessagesInLocalStorage()

  if (!currentChatMessages) {
    currentChatMessages = [newMessage]
    setChatMessagesInLocalStorage(currentChatMessages)
    return
  }

  currentChatMessages.push(newMessage)
  setChatMessagesInLocalStorage(currentChatMessages)

  return currentChatMessages
}

export function getChatIdInLocalStorage() {
  return localStorage.getItem(LOCAL_STORAGE_CHAT_ID)
}

export function removeChatIdInLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_CHAT_ID)
}

export function setChatIdInLocalStorage(value) {
  localStorage.setItem(LOCAL_STORAGE_CHAT_ID, value)
}
