export const boxShadowColor = '#A4BCD6BF'

export const chatPrimaryColor = '#004d34'

export const chatBackgroundColor = {
  dark: '#A4BCD6',
  light: '#D8E4F1'
}

export const chatIconBackgroundColor = '#FFFFFF'
export const chatStyleBackgroundColor = '#FFFFFF'
export const formBackgroundColor = '#FFFFFF'

export const buttonFontColor = '#FFFFFF'
export const inputFontColor = '#FFFFFF'
export const labelFormFontColor = '#283443'
export const messageFontColor = '#FFFFFF'
