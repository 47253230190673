export const getDepartmentsQuery = () => `
  query {
    departments (
      where: {
        deletedAt: null
      }
    ) {
      id
      displayInBot
      name
    }
  }
`
