import {
  boxShadowColor,
  buttonFontColor,
  chatBackgroundColor,
  chatIconBackgroundColor,
  chatPrimaryColor,
  chatStyleBackgroundColor,
  formBackgroundColor,
  inputFontColor,
  labelFormFontColor,
  messageFontColor
} from './colors'

export const chatButtonIconStyle = `
  #public-chat {
    align-items: center;
    background-color: ${chatIconBackgroundColor};
    border-radius: 50%;
    bottom: 10px;
    box-shadow: 0px 0px 5px 0px ${boxShadowColor};
    cursor: pointer;
    display: flex;
    height: 70px;
    justify-content: center;
    padding: 0;
    position: fixed;
    right: 10px;
    width: 70px;
    z-index: 99999;
  }
`

export const chatStyle = `
  #public-chat {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;

    bottom: 10px;
    position: fixed;
    right: 10px;
    z-index: 99999;

    background-color: ${chatStyleBackgroundColor};
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px ${boxShadowColor};
    cursor: default;

    height: 500px;
    padding: 10px;
    width: 350px;
  }

  #public-chat .chat {
    background-color: ${chatBackgroundColor.light};
    border-radius: 5px;

    display: grid;
    grid-template-rows: 1fr 50px;

    height: calc(100% - 10px);
    width: 100%;
  }

  #public-chat .chat #messages {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    padding: 10px;

    height: 100%;
    overflow-y: auto;
  }

  #public-chat .chat #messages .message {
    display: flex;
    width: 100%;
  }

  #public-chat .chat #messages .message.admin,
  #public-chat .chat #messages .message.bot {
    flex-direction: row;
  }

  #public-chat .chat #messages .message.client {
    flex-direction: row-reverse;
  }

  #public-chat .chat #messages .message > div {
    background-color: ${chatBackgroundColor.dark};
    border-radius: 10px;
    max-width: 65%;
    padding: 10px;
  }

  #public-chat .chat #messages .message > div p {
    color: ${messageFontColor};
  }

  #public-chat .chat form {
    background-color: ${chatBackgroundColor.dark};
    border-radius: 0 0 5px 5px;
    display: grid;
    grid-template-columns: 1fr 40px;
    gap: 5px;
    padding: 5px;
  }

  #public-chat .chat form input {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
  }

  #public-chat .chat form button {
    background-color: transparent;
    border: none;
    width: 40px;
  }
`

export const formStyle = `
  #public-chat {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;

    bottom: 10px;
    position: fixed;
    right: 10px;
    z-index: 99999;

    background-color: ${formBackgroundColor};
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px ${boxShadowColor};
    cursor: default;

    height: 500px;
    padding: 25px;
    width: 350px;
  }

  .public-chat-description {
    color: ${labelFormFontColor};
    font-weight: 600;
    text-align: center;
  }

  #public-chat form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  #public-chat form section {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
    width: 100%;
  }

  #public-chat form section label {
    color: ${labelFormFontColor};
    font-size: 14px;
    font-weight: 600;
  }

  #public-chat form section div {
    align-items: center;
    background-color: ${chatBackgroundColor.dark};
    border-radius: 10px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
  }

  #public-chat form section select {
    background: url(https://i.ibb.co/Jvs5jB6/Dropdown-Arrow.png) no-repeat;
    background-position: right 10px top 7.5px;
    background-size: 25px;
    align-items: center;
    background-color: ${chatBackgroundColor.dark};
    border-radius: 10px;
    color: ${inputFontColor};
    display: flex;
    font-weight: 500;
    height: 40px;
    justify-content: space-between;
    line-height: 20px;
    padding: 10px;
    width: 100%;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
  }

  #public-chat form section select option {
    color: ${inputFontColor};
    font-weight: 500;
  }

  #public-chat form  section div input {
    background-color: transparent;
    border: none;
    color: ${inputFontColor};
    font-weight: 500;
    outline: none;
  }

  #public-chat form  section div input::placeholder {
    color: ${inputFontColor};
    opacity: 0.5;
  }

  #public-chat form button {
    background-color: ${chatPrimaryColor};
    border: none;
    border-radius: 10px;
    color: ${buttonFontColor};
    font-weight: 600;
    height: 40px;
    margin-top: 10px;
    width: 100%;
  }
`
