export const chatIcon = color => `
  <svg
    width=40
    height=40
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 5C29.1666 5 36.6666 10.9667 36.6666 18.3333C36.6666 25.7 29.1666 31.6667 20 31.6667C17.9333 31.6667 15.95 31.3667 14.1166 30.8333C9.24998 35 3.33331 35 3.33331 35C7.21665 31.1167 7.83331 28.5 7.91665 27.5C5.08331 25.1167 3.33331 21.8833 3.33331 18.3333C3.33331 10.9667 10.8333 5 20 5Z'
      fill=${color}
    />
  </svg>
`
