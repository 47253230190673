import { chatIcon } from '../assets/icon/chat'
import { getUnmaskedValue } from '../common/mask'
import {
  getChatIdInLocalStorage,
  setChatIdInLocalStorage,
  setNewMessageInLocalStorageChatMessages
} from '../common/storage'
import { graphQLClient } from '../config/client'
import { startAPIChatMutation } from '../graphql/startAPIChatMutation'
import { chatPrimaryColor } from '../theme/colors'
import { chatButtonIconStyle } from '../theme/styles'
import { chatMessagesHandler } from './chatMessages'
import { setFormSectionAttributes } from './form'

let clicked = false
let section = null
let showChat = false

export async function generatePublicChat() {
  const documentExists = !!document
  if (!documentExists) return

  section = document.getElementById('public-chat')
  await setAttributesInSection()

  section.addEventListener('click', () => {
    if (clicked) return
    clicked = !clicked
    setAttributesInSection()
  })
}

export function hideChat() {
  clicked = false
  showChat = false
}

export async function setAttributesInSection() {
  const chatIdStorage = getChatIdInLocalStorage()
  const chatExists = chatIdStorage

  if (chatExists) {
    clicked = true
    showChat = true
  }

  if (!clicked) {
    setNotClickedSectionAttributes()
    return
  }

  if (!showChat) {
    section = document.getElementById('public-chat')

    await setFormSectionAttributes({
      section,
      submitForm
    })

    return
  }

  chatMessagesHandler({
    section
  })
}

function setNotClickedSectionAttributes() {
  section.innerHTML = `
    <style type='text/css'>${chatButtonIconStyle}</style>
    ${chatIcon(chatPrimaryColor)}
  `
}

async function submitForm(submitData) {
  const mutation = startAPIChatMutation({
    cpf: getUnmaskedValue(submitData.cpf),
    departmentId: submitData.department,
    name: submitData.name
  })

  const response = await graphQLClient(mutation)

  showChat = true

  if (!response?.data) {
    hideChat()
    return
  }

  const chatId = response.data.startAPIChat.chatId
  setChatIdInLocalStorage(chatId)

  const initialMessage = response.data.startAPIChat.defaultMessage
  setNewMessageInLocalStorageChatMessages(initialMessage)

  chatMessagesHandler({
    initialMessages: [initialMessage],
    section
  })
}
