import { API_URL, API_URL_WS, COMPANY_SLUG } from './dotEnv'

const headers = {
  'Company-Slug': COMPANY_SLUG,
  'Content-Type': 'application/json'
}

export async function graphQLClient(query, variables = {}) {
  try {
    const response = await fetch(API_URL, {
      body: JSON.stringify({
        query,
        variables
      }),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers,
      method: 'POST',
      mode: 'cors',
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    })

    return response.json()
  } catch (error) {
    console.error(error)
  }
}

const headersSubscription = {
  ...headers,
  isPublic: true
}

export function graphQLClientSubscription() {
  const socket = new WebSocket(API_URL_WS, 'graphql-ws')

  socket.onopen = () => {
    const connectionInit = JSON.stringify({
      payload: {
        ...headersSubscription
      },
      type: 'connection_init'
    })

    socket.send(connectionInit)
  }

  return socket
}
