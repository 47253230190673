import { chatHandler } from '..'
import { sendIcon } from '../assets/icon/send'
import {
  getChatIdInLocalStorage,
  getChatMessagesInLocalStorage,
  removeChatIdInLocalStorage,
  removeChatMessagesInLocalStorage,
  setNewMessageInLocalStorageChatMessages
} from '../common/storage'
import {
  graphQLClient,
  graphQLClientSubscription
} from '../config/client'
import { getPublicChatMessages } from '../graphql/getPublicChatMessages'
import { messageAddedSubscription } from '../graphql/messageAddedSubscription'
import { sendAPIClientMessageMutation } from '../graphql/sendAPIClientMessageMutation'
import { chatBackgroundColor } from '../theme/colors'
import { chatStyle } from '../theme/styles'
import { hideChat } from './chat'

export async function chatMessagesHandler({
  initialMessages = null,
  section
}) {
  await setChatMessagesSectionAttributes({ initialMessages, section })
  await subscriptionHandler(section)
}

async function setChatMessagesSectionAttributes({
  initialMessages = null,
  section
}) {
  let messages = initialMessages

  if (!messages) {
    const chatId = getChatIdInLocalStorage()
    const query = getPublicChatMessages({ chatId })
    let chatMessagesData = null

    try {
      chatMessagesData = await graphQLClient(query)

      const chatAlreadyClosed =
        chatMessagesData?.errors?.[0]?.message ===
        'CHAT_ALREADY_CLOSED'

      const chatIsNotPublic =
        chatMessagesData?.errors?.[0]?.message ===
        'CHAT_IS_NOT_PUBLIC'

      if (chatAlreadyClosed || chatIsNotPublic) {
        await closeChat()
        return
      }

      messages = chatMessagesData?.data?.publicChatMessages.data
        .sort()
        .reverse()
    } catch (error) {
      console.error(error)
    }

    if (!chatMessagesData?.data) {
      messages = getChatMessagesInLocalStorage()
    }
  }

  section.innerHTML = `
    <style type='text/css'>${chatStyle}</style>
    
    <div class='chat'>
      <div id='messages'>
        ${await generateChatMessages(messages)}
      </div>

      <form id='send-message-form'>
        <input
          name='message'
          placeholder='Digite aqui...'
          type='text'
        >
        <button>${sendIcon(chatBackgroundColor.light)}</button>
      </form>
    </div>
  `

  await addFormSubmit()
}

async function generateChatMessages(initialMessages) {
  let elementMessages = ''

  initialMessages
    ?.slice()
    ?.reverse()
    ?.forEach(message => {
      elementMessages += `
        <div class='message ${
          message.subject === 'CLIENT' ? 'client' : 'admin'
        }'
        >
          <div><p>${message.text}</p></div>
        </div>
      `
    })

  return elementMessages
}

async function addFormSubmit() {
  const form = document.getElementById('send-message-form')

  form.addEventListener('submit', async event => {
    event.preventDefault()

    const text = event.target.message.value
    const chatId = getChatIdInLocalStorage()

    const mutation = sendAPIClientMessageMutation({
      chatId,
      text
    })

    const response = await graphQLClient(mutation)

    if (response?.data?.sendAPIClientMessage?.success) {
      form.reset()
    }
  })
}

async function subscriptionHandler(section) {
  const chatId = getChatIdInLocalStorage()
  const subscription = messageAddedSubscription({ chatId })

  let subscriptionClient = graphQLClientSubscription()
  const connectionStartId = 1

  function onConnectionStart() {
    const connectionStart = JSON.stringify({
      id: connectionStartId,
      payload: {
        query: subscription,
        variables: {}
      },
      type: 'start'
    })

    try {
      subscriptionClient.send(connectionStart)
    } catch (error) {
      console.error('error', error)
    }
  }

  subscriptionClient.onmessage = async event => {
    const data =
      typeof event.data === 'string'
        ? JSON.parse(event.data)
        : event.data

    if (data.type === 'connection_ack') {
      onConnectionStart()
      return
    }

    if (event.type === 'message' && !!event.data) {
      const messageData = JSON.parse(event.data)

      if (messageData?.payload?.data?.messageAdded) {
        setNewMessageInLocalStorageChatMessages(
          messageData.payload.data.messageAdded
        )
      }

      const messages = getChatMessagesInLocalStorage()

      await setChatMessagesSectionAttributes({
        initialMessages: messages,
        section
      })

      if (
        messageData?.payload?.data?.messageAdded?.subscriptionExtraData
          ?.chatWasClosed
      ) {
        const formElement = document.getElementById(
          'send-message-form'
        )
        formElement.setAttribute('style', 'display: none')

        setTimeout(async () => {
          await closeChat()
        }, 10000)
      }
    }
  }

  subscriptionClient.onerror = error => {
    console.error('Error:', error)
  }

  subscriptionClient.onclose = async () => {
    const chatId = getChatIdInLocalStorage()
    const chatMessages = getChatMessagesInLocalStorage()

    if (chatId && chatMessages) {
      subscriptionHandler(section)
    }
  }
}

async function closeChat() {
  hideChat()
  removeChatMessagesInLocalStorage()
  removeChatIdInLocalStorage()
  await chatHandler()
}
