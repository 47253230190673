export const startAPIChatMutation = ({ cpf, departmentId, name }) => `
  mutation {
    startAPIChat(
      client: {
        identification: "${cpf}"
        name: "${name}"
      }
      departmentId: "${departmentId}"
    ) {
      chatId
      defaultMessage {
        id
        createdAt
        subject
        text
      }
    }
  }
`
