import IMask from '../modules/imask'

export function getMaskedValue(mask, value) {
  const masked = IMask.createMask({
    mask
  })

  return masked.resolve(String(value))
}

export function getUnmaskedValue(value) {
  return value.replace(/\D/g, '')
}
