import { cpfIcon } from '../assets/icon/cpf'
import { nameIcon } from '../assets/icon/name'
import { getMaskedValue } from '../common/mask'
import { graphQLClient } from '../config/client'
import { getDepartmentsQuery } from '../graphql/getDepartmentsQuery'
import { formStyle } from '../theme/styles'

export async function setFormSectionAttributes({
  section,
  submitForm
}) {
  const formLayout = await generateFormLayout()

  section.innerHTML = `
    <style type='text/css'>${formStyle}</style>
    <script src='https://unpkg.com/imask'></script>

    <p class='public-chat-description'>Olá. Para iniciar o atendimento via chat preencha o formulário abaixo.</p>
    ${formLayout}
  `

  generateFormScripts(submitForm)

  section.setAttribute('style', '')
}

async function generateFormLayout() {
  const query = getDepartmentsQuery()
  const response = await graphQLClient(query)

  let departmentOptions = ''

  response?.data?.departments?.forEach(department => {
    if (!department?.displayInBot) return
    departmentOptions += `
      <option value="${department.id}">${department.name}</option>
    `
  })

  return `
    <form id='public-chat-form'>
      <section>
        <label for='name'>
          Nome:
        </label>
        <div>
          <input
            name='name'
            placeholder='Nome'
            type='text'
          >
          ${nameIcon('#FFFFFF')}
        </div>
      </section>
          
      <section>
        <label for='cpf'>
          CPF:
        </label>
        <div>
          <input
            name='cpf'
            placeholder='CPF'
            type='text'
          >
          ${cpfIcon('#FFFFFF')}
        </div>
      </section>
          
      <section>
        <label for='department'>
          Setor:
        </label>
        <select name="department">
          <option value="" disabled selected>Escolha um setor...</option>
          ${departmentOptions}
        </select>
      </section>

      <button type='submit'>Enviar</button>
    </form>
  `
}

function generateFormScripts(submitForm) {
  const inputCpf = document.getElementsByName('cpf')[0]

  inputCpf.addEventListener('keyup', e => {
    const initialValue = e.target.value

    const onlyNumberValue = initialValue.replace(/\D/g, '')
    const maskedValue = getMaskedValue(
      '000.000.000-00',
      onlyNumberValue
    )

    inputCpf.value = maskedValue
  })

  const form = document.getElementById('public-chat-form')

  form.addEventListener('submit', event => {
    event.preventDefault()

    const submitData = {}

    submitData[event.target.cpf.name] = event.target.cpf.value
    submitData[event.target.department.name] =
      event.target.department.value
    submitData[event.target.name.name] = event.target.name.value

    submitForm(submitData)
  })
}
