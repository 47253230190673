export const messageAddedSubscription = ({ chatId }) => `
  subscription {
    messageAdded(chatId: "${chatId}") {
      id
      adminId
      chatId
      subject
      text
      type
      waMessageDateTime
      waMessageId
      createdAt
      deletedAt
      updatedAt
      file {
        id
        name
        url
        type
      }
      subscriptionExtraData {
        chatWasClosed
      }
    }
  }
`
