export const nameIcon = color => `
  <svg
    width=30
    height=30
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 3.33333C10.8841 3.33333 11.7319 3.68452 12.357 4.30964C12.9822 4.93476 13.3333 5.78261 13.3333 6.66666C13.3333 7.55072 12.9822 8.39856 12.357 9.02368C11.7319 9.64881 10.8841 9.99999 10 9.99999C9.11595 9.99999 8.26811 9.64881 7.64299 9.02368C7.01787 8.39856 6.66668 7.55072 6.66668 6.66666C6.66668 5.78261 7.01787 4.93476 7.64299 4.30964C8.26811 3.68452 9.11595 3.33333 10 3.33333ZM10 11.6667C13.6833 11.6667 16.6667 13.1583 16.6667 15V16.6667H3.33334V15C3.33334 13.1583 6.31668 11.6667 10 11.6667Z'
      fill=${color}
    />
  </svg>
`
