export const sendIcon = color => `
  <svg
    width=30
    height=30
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.66669 17.5L19.1667 10L1.66669 2.5V8.33333L14.1667 10L1.66669 11.6667V17.5Z'
      fill=${color}
    />
  </svg>
`
